import {AdminPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/instructors',
        name: 'instructors-list',
        component: () => import(/* webpackChunkName: "tutor" */ '~/views/instructor/instructor-list.vue'),
        meta: {
            permission: AdminPermission.Instructor
        }
    },
    {
        path: '/instructor/:_id',
        name: 'instructor',
        component: () => import(/* webpackChunkName: "tutor" */ '~/views/instructor/instructor-index.vue'),
        meta: {
            permission: AdminPermission.Instructor
        }
    },
    {
        path: '/invoice-list',
        name: 'invoice-list',
        component: () => import(/* webpackChunkName: "tutor-invoice" */ '~/views/invoice/invoice-list.vue'),
        meta: {
            permission: AdminPermission.Invoice
        }
    },
    {
        path: '/invoice/:id',
        name: 'invoice',
        component: () => import(/* webpackChunkName: "tutor-invoice" */ '~/views/invoice/invoice.vue'),
        meta: {
            permission: AdminPermission.Invoice
        }
    }
]

export default routes
