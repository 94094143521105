import {Course} from '~/components/data-class/data-class'
import {SubjectList} from '@afterschool.dev/as-data-admin'
import {auth} from '~/plugins/auth'
import {createRequest} from '~/utils/network-request'

export function isEmpty(obj: any): boolean { // == null of js
    return obj === undefined || obj === null
}

export function isEmptyString(str: any): boolean { // == null of js
    return str === undefined || str === null || str === ''
}

export function quote(str: string) {
    return `\"${str}\"`
}

export function toCsv(table: string[][]) {
    return table.map((row) => {
        return row.map((cell) => {
            return quote(cell)
        }).join(',')
    }).join('\n')
}

export type Dict<T> = { [key: string]: T }

export function arrayToDict<T>(array: T[], keyField: string = '_id'): Dict<T> {
    const dir: Dict<T> = {}
    for (const item of array) {
        const key = item[keyField]
        dir[key] = item
    }
    return dir
}


declare global {
    interface String {
        digits(): string;
    }

    interface Array<T> {
        asyncMap<K>(callback: (item: T) => Promise<K>): Promise<Array<K>>;

        toDict(key?: string): Dict<T>;

        unique(): Array<T>;

        last(): T;
    }
}

String.prototype.digits = function (this: string) {
    return this.replace(/\D/g, '')
}

Array.prototype.asyncMap = function <T, K>(this: Array<T>, callback: (T) => Promise<K>) {
    const ps = this.map(callback)
    return Promise.all(ps)
}

Array.prototype.toDict = function (key = '_id') {
    return arrayToDict(this, key)
}

Array.prototype.unique = function <T>(this: Array<T>) {
    return Array.from(new Set(this))
}

Array.prototype.last = function <T>(this: Array<T>) {
    return this[this.length - 1]
}

export function humanFileSize(bytes: number, si = true) {
    let thresh = si ? 1000 : 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    let units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    do {
        bytes /= thresh
        ++u
    } while (Math.abs(bytes) >= thresh && u < units.length - 1)
    return bytes.toFixed(1) + ' ' + units[u]
}

export function initImageViewer(el: Element, url: string) {
    el.innerHTML = ''
    const img = document.createElement('img')
    img.src = url
    const eid = 'viewer-img'
    img.id = eid
    img.style.display = 'none'
    el.appendChild(img)
    new Viewer(img, {
        inline: true,
        navbar: false,
        title: false,

        toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 0,
            reset: 1,
            prev: 0,
            play: 0,
            next: 0,
            rotateLeft: 1,
            rotateRight: 1,
            flipHorizontal: 1,
            flipVertical: 1,
        },

        viewed() {
            const w = this.viewer.images[0].width
            this.viewer.zoomTo(560 / w).move(0, -1 * this.viewer.imageData.top)

            const ws = document.getElementsByClassName('el-dialog__wrapper') as HTMLCollectionOf<HTMLElement>
            let wrapper: HTMLElement | null = null
            for (const item of ws) {
                if (item.style.display !== 'none')
                    wrapper = item
            }

            if (wrapper) {
                const d = wrapper.getElementsByClassName('el-dialog') as HTMLCollectionOf<HTMLElement>
                d[0].style.marginTop = '1vh'
                d[0].style.marginBottom = '0'
            }
        }
    })
}

export function tenToDot(value: number) {
    let str = value + ''
    const splitted = str.split('.')
    str = splitted[0]
    let originalDecimalPlaces = ''
    if (splitted.length === 2) {
        originalDecimalPlaces = splitted[1]
    }
    return [str.slice(0, str.length - 1) || '0', '.', str.slice(str.length - 1), originalDecimalPlaces].join('')
}

export function numberWithComma(m: string | number) {
    return m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const defaultObj = () => {
    return {}
}
export const opDefaultObj = {default: defaultObj}
export const defaultArr = () => {
    return []
}
export const opDefaultArr = {default: defaultArr}

export async function toAdminv(path: string = '') {
    if (auth.isLoggedIn) {
        const res = await createRequest('/auth/login-token', 'get').send()
        if (res && res.data.login_token) {
            const query = `?tkn=${res.data.login_token}`
            const url = `${process.env.VUE_APP_DOMAIN_ADMINV}${path}${query}`
            const link = window.open(url, '_blank')
            if (link) link.focus()
        }
    }
}

export function webDomain() {
    return process.env.VUE_APP_WEB_DOMAIN ||
    location.href.startsWith('https://admin.afterschool.com') ? 'https://afterschool.com.hk' : 'https://dev.afterschool.com.hk'
}

export function userPropicURL(memberId: string, dimension?: number) {
    return webDomain() + `/images/profile-picture/${memberId}-propic${dimension ? '-w' + dimension : ''}.jpg`
}

export function courseLink(course?: Course) {
    if (!course) return '/dse-course/'
    const subject = SubjectList.allTag[course.subject] || {enameFull: ''}
    return `${webDomain()}/dse-course/${subject.enameFull}/${course._id}-${course.url_title}/`
}

/******************
 * Parse faqs from text
 ******************/
export function faqTextToData(text:string = '') {
    try {
        //  Split faq by 4 newline (= 3 blank line)
        const faqsText = text.split('\n\n\n\n')
        return   faqsText.reduce((result, item )=> {
            //  Split faq by 3 newline (= 2 blank line)
            const faq = item.split('\n\n\n')
            return [
                ...result,
                {
                    q: (faq[0] || '').trim(),
                    a: (faq[1] || '').trim().replace(/(?:\r\n|\r|\n)/g, '<br />')
                }
            ]
        }, [])
    } catch (e) {
        console.log(e)
        return []
    }
}
