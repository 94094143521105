import _ from 'lodash'
import {Vue} from 'vue-property-decorator'
import {AdminPermission} from '~/utils/permissions'
import store from '~/store'

export function checkPermissions(required: string[] | string) {
    if (!required)
        return true

    const permission = store.getters['user/permission']

    //  If permission includes all means full access
    if (permission.includes(AdminPermission.All))
        return true

    //  If one or more permission, and check for difference
    const requiredList = _.isArray(required) ? required : [required]
    return _.difference(requiredList, permission).length === 0
}

const Permission = {
    install(Vue, options) {
        Vue.prototype.checkPermissions = checkPermissions
    },
}
Vue.use(Permission)
