import _ from 'lodash'
import CommunityRoutes from '~/router/community.routes'
import ContentRoutes from '~/router/content.routes'
import CourseRoutes from '~/router/course.routes'
import CreativeRoutes from '~/router/creative.routes'
import EcommerceRoues from '~/router/ecommerce.routes'
import MemberRoutes from '~/router/member.routes'
import PageRoutes from '~/router/page.routes'
import PromotionRoutes from '~/router/promotion.routes'
import ReportRoutes from '~/router/report.routes'
import TutorRoutes from '~/router/tutor.routes'
import WhatsappRoutes from '~/router/whatsapp.routes'

let routes: any[] = [
    // {
    //     path: '*',
    //     redirect: '/'
    // },
    {
        path: '/',
        redirect: '/posts'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '~/views/auth/LoginPage.vue'),
        meta: {
            layout: 'auth',
            noAuth: true
        }
    },

    ...CommunityRoutes,
    ...ContentRoutes,
    ...CourseRoutes,
    ...CreativeRoutes,
    ...EcommerceRoues,
    ...MemberRoutes,
    ...PageRoutes,
    ...PromotionRoutes,
    ...ReportRoutes,
    ...TutorRoutes,
    ...WhatsappRoutes,
]

export const mergedRoutes = routes

export const mergedRouteDict = _.reduce(mergedRoutes, (result, item) => {
    if (!item.name) {
        return result
    }
    return {
        ...result,
        [item.name]: item
    }
}, {})
