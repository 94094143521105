import {Member} from '~/data/user'
import {createRequest} from '~/utils/network-request'
import {toInstanceForce} from '~/utils/Serializer'
import {getFromLocalStorage, setToLocalStorage} from '~/utils/Storage'


export const strict = false

const selfCacheKey = 'self-profile-cache'

const state = () => ({
    isLogin: false,
    member: null,
    permissions: [],
    selfCache: {}
})

const getters = {
    userId(state) {
        if (!!state.member)
            return state.member._id
    },
    isLogin(state) {
        return state.isLogin && !!state.member
    },
    member(state) {
        if (!!state.member)
            return state.member
        return null
    },
    permission: state => state.permissions
}
const mutations = {
    setIsLogin: (state, isLogin) => state.isLogin = isLogin,
    setMember: (state, member) => state.member = member,
    setPermissions: (state, permissions) => state.permissions = permissions,
    setSelfCache(state, cache) {
        state.selfCache = cache
        try {
            setToLocalStorage(selfCacheKey, cache)
        } catch {
            //QuotaExceededError
        }
    },
}
const actions = {
    userLoginWithToken({commit}, token) {
        return createRequest(`login/token`, 'post', {}, {login_token: token}).send(false)
            .then(res => {
                this._vm.$auth.login(res.data.member_id, res.data)
                return res.data.member_id
            }).catch(e => {
                console.log(e)
                return false
            })
    },
    userLogout() {
        this._vm.$auth.logout()
        location.replace('/login')
    },
    fetchUserData({commit, dispatch}, uid) {
        commit('setIsLogin', !!uid)
        return Promise.all([dispatch('fetchSelfProfile', uid), dispatch('fetchUserPermission', uid)])
    },
    fetchProfile({commit, dispatch}, uid) {
        return createRequest(`${uid}/user-profile`, 'get').send(false)
            .then(res => {
                const member = toInstanceForce(new Member(), res.data)
                return res.data
            }).catch(e => {
                return false
            })
    },
    fetchSelfProfile({commit, state}, uid) {
        if (!state.selfCache.etag) {
            state.selfCache = getFromLocalStorage(selfCacheKey) || state.selfCache
        }
        const mid = uid || this._vm.$auth.memberId
        return createRequest(`${mid}/user-profile/self`, 'get')
            .setCache(state.selfCache).send()
            .then(async res => {
                commit('setSelfCache', res.cache)
                const member = toInstanceForce(new Member(), res.cache!!.value)
                commit('setMember', member)
                return member
            }).catch(e => {
                return false
            })
    },
    fetchUserPermission({state, commit}, uid) {
        const mid = uid || this._vm.$auth.memberId
        return createRequest(`member/${mid}/permissions`, 'get').send()
            .then(res => {
                const permissions = res.data.permissions || []
                commit('setPermissions', permissions)
                return permissions
            }).catch(e => {
                return false
            })
    }
}

const store = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default store
