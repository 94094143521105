import LZString from 'lz-string'

export function setToLocalStorage(key: string, value: any) {
    localStorage.setItem(key, LZString.compress(JSON.stringify(value)))
}

export function getFromLocalStorage(key: string) {
    const storageStr = localStorage.getItem(key)
    if (storageStr)
        return JSON.parse(LZString.decompress(storageStr))
    else
        return null
}
