import axios, {AxiosResponse, Method, ResponseType} from 'axios'
import {auth} from '~/plugins/auth'

export let serverBaseURL = 'https://server.com/afterschool/api/v1/'

export function getServerBaseURL() {
    return serverBaseURL
}

export interface DataObject {
    [key: string]: any
}


export interface Cache {
    etag: string
    value: any
}

export interface Response extends AxiosResponse {
    cache?: Cache
}

export function setServer(domain: string) {
    serverBaseURL = domain
}

type Query = string | number | boolean

export class NetworkRequest {
    url: string
    method: string
    params: { [key: string]: Query }
    body: { [key: string]: any } | FormData
    header: { [key: string]: string }
    responseType: ResponseType
    cache?: Cache

    constructor(url: string, method: string, params: { [key: string]: Query } = {},
                body: object = {}, header: { [key: string]: string } = {}, responseType: ResponseType = 'json') {
        this.url = url
        this.method = method
        this.params = params
        this.body = body
        this.header = header
        this.responseType = responseType
    }

    setCache(c: Cache): NetworkRequest {
        this.cache = c
        if (c.etag)
            this.header['If-None-Match'] = c.etag
        return this
    }

    async send(requireAuth: boolean = true, printError: boolean = false): Promise<Response> {
        if (requireAuth) {
            this.header.Authorization = 'Bearer ' + await auth.getAccessToken()
        }

        //  Add support for external domain
        const isExternal = this.url.startsWith('http')
        const reqBaseURL = isExternal ? this.url : serverBaseURL
        const reqURL = isExternal ? '' : this.url

        let rawRes: (void | Response)
        try {
            rawRes = await axios.request<DataObject>({
                method: this.method as Method,
                headers: this.header,
                baseURL: reqBaseURL,
                params: this.params,
                url: reqURL,
                data: this.body,
                responseType: this.responseType,
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status === 304
                }
            })

            if (rawRes.headers.etag) {
                const cache = {
                    etag: rawRes.headers.etag,
                    value: (rawRes.status === 304) ? this.cache!!.value : rawRes.data
                }
                if (this.cache)
                    Object.assign(this.cache, cache)
                else
                    this.cache = cache
                rawRes.cache = cache
                rawRes.data = cache.value
            }
        } catch (error) {
            if (printError) {
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            alert(error.response.data.message)
                            console.log(error.response.data.message)
                        } else {
                            console.log(error.response.data)
                        }
                    } else {
                        console.log(error.response)
                    }
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
            }
        }
        if (rawRes) return rawRes
        throw {message: 'response is void'}
    }
}

export function createRequest(url: string, method: string = 'get', params: { [key: string]: Query } = {},
                              body: object | FormData = {}, header: { [key: string]: string } = {}): NetworkRequest {
    return new NetworkRequest(url, method, params, body, header)
}
