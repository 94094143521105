import {BannerPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/banner-app',
        name: 'banner-app',
        component: () => import(/* webpackChunkName: "banner" */ '~/_deprecated/views/banner/app-banners.vue'),
        meta: {
            permission: BannerPermission.AppBanner
        }
    }
]

export default routes
