import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PaginationMixin extends Vue {
    step: number = 10
    totalCount: number = 0
    currentPage: number = 1

    // render range
    itemsLength: number = 0
    itemUnit: string = ''

    get skip() {
        return (this.currentPage - 1) * this.step
    }

    get currentRange() {
        return `${this.skip + 1}-${this.skip + this.itemsLength} of ${this.totalCount} ${this.itemUnit}`
    }

    async toPage(pageNumber: number) {
        this.currentPage  = pageNumber
        await this.updateData()
    }

    async updateData() {}
}
