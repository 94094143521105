export default class Serializable {
  static from(raw: object): any {
    const obj = new this()
    for (const propName of Object.keys(obj)) {
      const type = typeof obj[propName]
      const key: string = propName
      if (raw[key] !== undefined){
        if(type !== typeof raw[key])
          console.error(key + ' should be in type ' + type)
        obj[propName] = raw[key]
      }
    }

    return obj
  }
}
