import Meta from 'vue-meta'
import {Vue} from 'vue-property-decorator'
import Router from 'vue-router'
import {checkPermissions} from '~/plugins/permission'
// Routes
import {mergedRouteDict, mergedRoutes} from '~/router/merge.routes'
import store from '~/store'

//  Fix: vue-router.esm.js?fe87:971 Uncaught (in promise) TypeError: Cannot read property '_normalized' of undefined
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {   return routerPush.call(this, location).catch( error=> error) }

Vue.use(Router)
Vue.use(Meta)

export const routes = mergedRoutes
export const routeDict = mergedRouteDict

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL || '/',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition

        return {x: 0, y: 0}
    },
    routes
})
/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
    const noAuthRequired = to.matched.some((record) => record.meta.noAuth) || false
    const isLogin = (store.state as Object)['user'].isLogin

    if (isLogin) {
        if (to.name!.startsWith('login')) {
            let redirect = '/'
            if(!!to.query.next) redirect = to.query.next + ''
            return next(redirect)
        }
    } else {
        //  Set destination for post login
        if (!noAuthRequired) {
            window.sessionStorage.setItem('after-login', to.fullPath)
            return next(routeDict['login'].path)
        }
    }
    //  All other router
    if (checkPermissions(to.meta.permission)) {
        return next()
    } else {
        return next('/')
    }
})

/**
 * After each route update
 */
// router.afterEach((to, from) => {
// })

export default router
