import {Auth} from '@afterschool.dev/as-auth'
import {Vue} from 'vue-property-decorator'

//  paa = prod admin, daa = dev admin, saa = staging admin
let cookiePrefix: string = 'paa_'
if (process.env.VUE_APP_ENV_DEV) cookiePrefix = 'daa_'
if (process.env.VUE_APP_ENV_STAGING) cookiePrefix = 'saa_'

let cookieDomain: string = '.afterschool.com.hk'
if(process.env.VUE_APP_ENV_LOCAL)
    cookieDomain = ''

export const auth = new Auth((process.env.VUE_APP_DOMAIN_API || ''), cookieDomain, cookiePrefix)
Vue.prototype.$auth = auth
