import {OrderPermission} from '~/utils/permissions'
import {AdminPermission, ShopPermission} from '~/utils/permissions'
import ImportOfflineStudents from '~/_deprecated/views/order/import-offline-students.vue'
import ImportWaybillNumbers from '~/_deprecated/views/order/import-waybill-numbers.vue'
import MobilePurchaseRecords from '~/views/shop/mobile-purchase-records.vue'
import PurchaseRecords from '~/views/shop/purchase-records.vue'
import ShopItemList from '~/views/shop/shop-item-list.vue'
import ShopItem from '~/views/shop/shop-item.vue'

const routes = [
    {
        path: '/orders/mobile',
        name: 'mobile-view-order-list',
        component: () => import(/* webpackChunkName: "order" */ '~/views/order/mobile-orders-list.vue'),
        meta: {
            permission: OrderPermission.Order
        }
    },
    {
        path: '/shop-items',
        name: 'shop-items',
        component: () => import(/* webpackChunkName: "shop" */ '~/views/shop/shop-item-list.vue'),
        meta: {
            permission: ShopPermission.EditShopItems
        }
    },
    {
        path: '/shop/new-item',
        name: 'new-shop-item',
        component: () => import(/* webpackChunkName: "shop" */ '~/views/shop/shop-item.vue'),
        meta: {
            permission: ShopPermission.EditShopItems
        }
    },
    {
        path: '/shop/item/:id',
        name: 'shop-item',
        component: () => import(/* webpackChunkName: "shop" */ '~/views/shop/shop-item.vue'),
        meta: {
            permission: ShopPermission.EditShopItems
        }
    },
    {
        path: '/shop/purchase-records',
        name: 'shop-purchase-records',
        component: () => import(/* webpackChunkName: "shop" */ '~/views/shop/purchase-records.vue'),
        meta: {
            permission: ShopPermission.ShopPurchaseRecords
        }
    },
    {
        path: '/shop/mobile-purchase-records',
        name: 'mobile-shop-purchase-records',
        component: () => import(/* webpackChunkName: "shop" */ '~/views/shop/mobile-purchase-records.vue'),
        meta: {
            permission: ShopPermission.ShopPurchaseRecords
        }
    }
]

export default routes
