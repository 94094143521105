import {AdminPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/message-sender',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '~/views/message/message.vue'),
        meta: {
            permission: AdminPermission.SendWhatsapp
        }
    },
    {
        path: '/whatsapp-groups',
        name: 'whatsapp-groups',
        component: () => import(/* webpackChunkName: "whatsapp-groups" */'~/views/message/whatsapp-group.vue'),
        meta: {
            permission: AdminPermission.SendWhatsapp
        }
    },
    {
        path: '/whatsapp-broadcasts',
        name: 'whatsapp-broadcasts',
        component: () => import(/* webpackChunkName: "whatsapp-broadcasts" */ '~/views/message/whatsapp-broadcast.vue'),
        meta: {
            permission: AdminPermission.SendWhatsapp
        }
    }
]

export default routes
