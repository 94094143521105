export const strict = false

const state = () => ({
    noFrame: false,
    /**
     *  Width, Height
     */
    screenWidth: 0,
    screenHeight: 0

})

const getters = {
    screenWidth: state => state.screenWidth,
    screenHeight: state => state.screenHeight,
}

const mutations = {
    /**
     * Theme
     */
    setNoFrame: (state, noFrame) => state.noFrame = noFrame,
    setScreenWidthHeight(state, data) {
        state.screenWidth = data.width
        state.screenHeight = data.height
    }
}

const actions = {
    setNoFrame({commit}) {
        commit('setNoFrame', true)
    },
}

const store = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}


export default store

