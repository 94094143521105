import {AdminPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/coupon',
        name: 'coupon',
        component: () => import(/* webpackChunkName: "coupon" */ '~/views/coupon/coupon.vue'),
        meta: {
            permission: AdminPermission.Coupon
        }
    }
]

export default routes
