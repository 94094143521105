import {CoursePermission, MemberPermission} from '~/utils/permissions'

const routes = [
    // {
    //     path: '/course/batch/new-class',
    //     name: 'batch-new-class',
    //     component: () => import(/* webpackChunkName: "batch-offline-class-editor" */ '~/views/course/new-course/batch-offline-class-editor.vue'),
    //     meta: {
    //         permission: CoursePermission.EditCourse
    //     }
    // },
    // {
    //     path: '/course/batch/edit-class',
    //     name: 'batch-edit-class',
    //     component: () => import(/* webpackChunkName: "batch-offline-class-editor" */ '~/views/course/new-course/batch-offline-class-editor.vue'),
    //     meta: {
    //         permission: CoursePermission.EditCourse
    //     }
    // },
    {
        path: '/course/tags',
        name: 'course-tags',
        component: () => import(/* webpackChunkName: "course-edit-review" */ '~/views/course/course-tags.vue'),
        meta: {
            permission: CoursePermission.CourseTag
        }
    },
    {
        path: '/course/enrollment-list',
        name: 'enrollment-list',
        component: () => import(/* webpackChunkName: "enrollment-list" */ '~/views/course/course-list/enrollment-list.vue'),
        meta: {
            permission: MemberPermission.EnrollmentList,
            classes: 'v-application--grey'
        }
    }
]

export default routes
