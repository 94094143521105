import {AdminPermission, BlogPermission} from '~/utils/permissions'

const routes = [
    {
        path: '/blogs',
        name: 'blog-list',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog-list.vue'),
        meta: {
            permission: BlogPermission.EditBlog
        }
    },
    {
        path: '/blog/create',
        name: 'new-blog',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog.vue'),
        meta: {
            permission: BlogPermission.EditBlog
        }
    },
    {
        path: '/blog/edit/:id',
        name: 'edit-blog',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog.vue'),
        meta: {
            permission: BlogPermission.EditBlog
        }
    },
    {
        path: '/blog-footers',
        name: 'blog-footer-list',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog-footer-list.vue'),
        meta: {
            permission: BlogPermission.EditBlogFooter
        }
    },
    {
        path: '/blog-footer/create',
        name: 'new-blog-footer',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog-footer.vue'),
        meta: {
            permission: BlogPermission.EditBlogFooter
        }
    },
    {
        path: '/blog-footer/edit/:id',
        name: 'edit-blog-footer',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog-footer.vue'),
        meta: {
            permission: BlogPermission.EditBlogFooter
        }
    },
    {
        path: '/blog-featured',
        name: 'blog-featured',
        component: () => import(/* webpackChunkName: "blog" */ '~/views/blog/blog-featured.vue'),
        meta: {
            permission: BlogPermission.EditBlogFeatured
        }
    }
]

export default routes
