const routes = [
    {
        path: '/posts',
        name: 'community-post-list',
        component: () => import(/* webpackChunkName: "community" */ '~/views/community/community-posts.vue'),
        meta: {}
    },
    {
        path: '/post/:id',
        name: 'community-post',
        component: () => import(/* webpackChunkName: "community" */ '~/views/community/post.vue'),
        meta: {}
    },
    {
        path: '/comments/:member_id',
        name: 'community-comments',
        component: () => import(/* webpackChunkName: "community" */ '~/views/community/comments.vue'),
        meta: {}
    }
]

export default routes
