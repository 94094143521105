import {Vue} from 'vue-property-decorator'
import Vuex from 'vuex'
import app from '~/store/app'
import user from '~/store/user'

Vue.use(Vuex)

export const strict = false

export const state = () => ({})
export const getters = {}
export const mutations = {}
export const actions = {}

const store = new Vuex.Store({
    modules: {
        app,
        user
    },
    strict: false
})

export default store
