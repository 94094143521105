import {ReportPermission} from '~/utils/permissions'
import SeriesReport from '~/views/report/series-report.vue'

const routes = [
    {
        path: '/tutor-dashboard',
        name: 'tutor-dashboard',
        component: () => import(/* webpackChunkName: "tutor-dashboard" */ '~/views/dashboard/tutor-dashboard.vue'),
        meta: {
            permission: ReportPermission.TutorPerformance
        }
    },
    {
        path: '/business-dashboard',
        name: 'business-dashboard',
        component: () => import(/* webpackChunkName: "business-dashboard" */ '~/views/dashboard/business-dashboard.vue'),
        meta: {
            permission: ReportPermission.BusinessPerformance
        }
    },
    {
        path: '/report/old-series-report',
        name: 'series-report',
        component: () => import(/* webpackChunkName: "series-report" */ '~/views/course/course-series-report.vue'),
        meta: {
            permission: ReportPermission.SeriesReport
        }
    },
    {
        path: '/report/series',
        name: 'report-series',
        component: () => import(/* webpackChunkName: "report-series" */ '~/views/report/series-report.vue'),
        meta: {
            permission: ReportPermission.SeriesReport
        }
    },
    {
        path: '/report/series/:id',
        name: 'report-series-id',
        component: () => import(/* webpackChunkName: "report-series" */ '~/views/report/series-id/series-id-report.vue'),
        meta: {
            permission: ReportPermission.SeriesReport
        }
    },
    {
        path: '/report/course',
        name: 'report-course',
        component: () => import(/* webpackChunkName: "report-course" */ '~/views/report/course-report.vue'),
        meta: {
            permission: ReportPermission.CourseReport
        }
    },
    {
        path: '/report/course/:id',
        name: 'report-course-id',
        component: () => import(/* webpackChunkName: "report-course" */ '~/views/report/course-id/course-id-report.vue'),
        meta: {
            permission: ReportPermission.CourseReport
        }
    },
    {
        path: '/report/course/:course_id/class/:class_id',
        name: 'report-course-id-class-id',
        component: () => import(/* webpackChunkName: "report-class" */ '~/views/report/class-id/class-id-report.vue'),
        meta: {
            permission: ReportPermission.CourseReport
        }
    }
]

export default routes
