export enum AdminPermission {
    All = 'all',
    AdminPage = 'admin-page',
    Event = 'event',
    Coupon = 'coupon',
    Instructor = 'instructor',
    Invoice = 'invoice',
    DSERecources = 'dse-resources',
    Setting = 'setting',
    IP = 'ip',
    SendWhatsapp = 'send-whatsapp',
    ImportData = 'import-data',
}

export enum MemberPermission {
    ViewMemberData = 'view:member-data',
    SetMemberData = 'set:member-data',

    ViewMemberPermissions = 'view:member-permissions',
    SetMemberPermissions = 'set:member-permissions',

    SetRole = 'set:role',
    SetPassword = 'set:password',

    Verifications = 'member-verifications',

    StudentList = 'student-list',
    EnrollmentList = 'enrollment-list',
}

export enum ReportPermission {
    Dashboard = 'dashboard',
    TutorPerformance = 'tutor-dashboard',
    BusinessPerformance = 'business-dashboard',
    SeriesReport = 'series-report',
    CourseReport = 'course-report'
}

export enum OrderPermission {
    Order = 'order-list',
    AbandonCart = 'abandon-cart',
    MakeupLesson = 'makeup-lesson',
    RefundRecord = 'refund-record',
    MailingList = 'mailing-list',
}

export enum CoursePermission {
    ViewCourse = 'view:course',
    EditCourse = 'edit:course',
    CreateNewVersion = 'create-new-version:course',
    CourseTag = 'course-tag',
    CourseSeries = 'course-series',
    StreamArchive = 'course-stream-archive',
    CourseReviews = 'course-reviews',
    CourseSchedule = 'course-schedule',
    LiveStreamPlayer = 'live-stream-preview',
    LiveStreamChatMessage = 'live-stream-chat-message',
    InventoryList = 'inventory-list'
}

export enum BlogPermission {
    EditBlog = 'blog-list',
    EditBlogFooter = 'blog-footer',
    EditBlogFeatured = 'blog-featured',
}

export enum ShopPermission {
    EditShopItems = 'shop-items',
    ShopPurchaseRecords = 'shop-purchase-records',
}

export enum BannerPermission {
    WebBanner = 'web-banner',
    AppBanner = 'app-banner',
}

export enum StickyBarPermission {
    StickyBar = 'sticky-bar'
}

export enum ShortenLinkPermission {
    View = 'view:shorten-link',
    Create = 'create:shorten-link',
    Edit = 'edit:shorten-link',
}

export enum LogPermission {
    View = 'view:log',
}

export enum ProofFactorPermission {
    View = 'view:proof-factor',
    Create = 'create:proof-factor',
    Edit = 'edit:proof-factor',
}

export enum PromotionPermission {
    BundleManager = 'bundle-manager',
    CampaignManager = 'campaign-manager'
}

export enum MessagePermission {
    BulkMessageAdmin = 'bulk-message-admin',
    BulkMessageTutor = 'bulk-message-tutor'
}
