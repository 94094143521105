import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#4dbbb6',
                secondary: '#6d7692',
                info: '#0095ff',
                warning: '#ffc232',
                error: '#ff3d71',
                success: '#00e096',
                dark: '#323643',
            }
        }
    },
    defaultAssets: {
        font: {
            family: 'Noto Sans HK'
        }
    }
}


export default new Vuetify(opts)


